<template>
  <section id="search-section">
    <b-row>
      <b-col md="12">
        <b-card class="mt-1 mb-0">
          <b-row>
            <b-col md="2"> Resim</b-col>
            <b-col md="1"> Ürün Id
              <b-button
                  size="sm"
                  variant="outline-success"
                  @click="params.orderBy='product_id'"
              >Seç
              </b-button>
            </b-col>
            <b-col md="3"> Ürün
              <b-button
                  size="sm"
                  variant="outline-success"
                  @click="params.orderBy='product_name'"
              >Seç
              </b-button>
            </b-col>
            <b-col md="1"> Adet
              <b-button
                  size="sm"
                  variant="outline-success"
                  @click="params.orderBy='t_quantity'"
              >Seç
              </b-button>
            </b-col>
            <b-col md="1"> Fiyat
            </b-col>
            <b-col md="2"> Top. Fiyat
              <b-button
                  size="sm"
                  variant="outline-success"
                  @click="params.orderBy='t_price'"
              >Seç
              </b-button>
            </b-col>

            <b-col md="2">
              <b-button-group size="sm">
                <b-button
                    variant="outline-success"
                    @click="params.sorting=1"
                >A-Z
                </b-button>
                <b-button
                    variant="outline-success"
                    @click="params.sorting=-1"
                >Z-A
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card class="mt-1 mb-0" v-for="(product,index) in orderBy(_orderProducts,params.orderBy,params.sorting)"
                :key="index">
          <b-row>
            <b-col md="2" class="imageContainer m-0 p-0">
              <div class="clearfix" v-if="product.images[0]" @click="showImages($event,product)">
                <b-img :src="'https://api.kitapfirsatlari.com/' + product.images[0].url" width="150"/>
              </div>
              <b-badge variant="danger" class="top-left" v-if="product.quantity>1">{{ product.quantity }}x</b-badge>
            </b-col>


            <b-col>
              {{ product.product_id }} <br>
              {{ product.product_name }} <br>
              {{ product.product_barcode }}<br>
              <b-badge v-if="product.product.product.brand">{{ product.product.product.brand.name }}</b-badge>
              <div>
                <b-badge
                    v-if="product.product_id"
                    variant="success"
                    size="sm"
                    v-b-popover.hover.top="'Ürün Bİlgileri Düzenle'"
                    @click="productEdit($event,product.product_id)">
                  <feather-icon icon="EditIcon"/>
                </b-badge>

                <b-badge
                    v-if="product.product_id"
                    variant="warning"
                    size="sm"
                    v-b-popover.hover.top="'Amz Sipariş Oluştur'"
                    @click="addNewOrder($event,product.product_id)">
                  <feather-icon icon="ShoppingCartIcon"/>
                </b-badge>

                <b-badge
                    v-if="product.product_id"
                    variant="info"
                    size="sm"
                    v-b-popover.hover.top="'Satış İstatistikleri'"
                    @click="getOrderProductStatistics($event,product.product_id)">
                  <feather-icon icon="BarChart2Icon"/>
                </b-badge>
              </div>
              <hr>
              <b-row>
                <b-col md="12">
                  <b-form-checkbox
                      v-model="showZeroStock"
                      checked="true"
                      name="check-button"
                      switch
                      inline
                  > &nbsp
                  </b-form-checkbox>
                  <a :href="supplierProduct.url"
                     class="btn btn-sm btn-outline-primary"
                     style="margin-right: 3px;padding-left: 1px;padding-right: 1px;"
                     target="_blank"
                     v-for="supplierProduct in product.product.supplier_products"
                     :key="supplierProduct.id"
                     v-if="![9,27].includes(supplierProduct.supplier_id) && (showZeroStock || supplierProduct.stock>0)"
                  >
                    {{ supplier_name(supplierProduct.supplier_id) }} - {{ supplierProduct.price }} ₺ -
                    {{ supplierProduct.stock }}
                    Ad.</a>
                </b-col>
                <b-col md="12" style="margin-top: 5px">
                  <b-button
                      v-for="(stock,index) in product.product.stocks"
                      :key="stock.id"
                      size="sm"
                      variant="outline-warning"
                      v-if="stock.quantity>0"
                      disabled
                      :title="supplier_name(stock.supplier_id) + ' ' + stock.shelve_name"
                  >{{ supplier_name(stock.stock_return) }} - {{ stock.price }} ₺ - {{ stock.quantity }} Ad.
                  </b-button>
                </b-col>
                <b-col md="12" v-if="product.product.product" style="margin-top: 5px">
                  <b-row>
                    <b-col md="12">
                      <a v-if="product.product.product.hb_products.length>0"
                         class="btn btnsize"
                         v-for="mpProduct in product.product.product.hb_products"
                         :key="mpProduct.id"
                         target="mpproduct"
                         :href="'https://www.hepsiburada.com/w-p-'+mpProduct.merchant_sku"
                         :class="mpProduct.merchant_sku==product.store_product_sku?'btn-primary':'btn-outline-warning'"
                      >HB</a>

                      <a v-if="product.product.product.ty_products.length>0"
                         class="btn btnsize"
                         v-for="mpProduct in product.product.product.ty_products"
                         :key="mpProduct.id"
                         target="mpproduct"
                         :href="'https://www.trendyol.com/w/w-w-p-'+mpProduct.productContentId"
                         :class="mpProduct.merchant_sku==product.store_product_sku?'btn-primary':'btn-outline-info'"
                      >TY</a>

                      <a v-if="product.product.product.az_products.length>0"
                         class="btn btnsize"
                         v-for="mpProduct in product.product.product.az_products"
                         :key="mpProduct.id"
                         target="mpproduct"
                         :href="'https://www.amazon.com.tr/gp/product/'+mpProduct.merchant_sku"
                         :class="mpProduct.merchant_sku==product.store_product_sku?'btn-primary':'btn-outline-success'"
                      >AMZ</a>


                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="12">
                  <b-button v-for="(stock_card,index5) in product.stock_cards"
                            :key="index5"
                            size="sm"
                            variant="info"
                  >
                    {{ stock_card.shelve_name }} ({{ stock_card.quantity }})
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="2">
              Adet: {{ product.t_quantity }}<br>
              Birim Fiyat: {{ (product.t_price / product.t_quantity).toFixed(2) }} <br>
              Toplam Fiyat: {{ (product.t_price).toFixed(2) }}
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import ProductImages from "@/components/ProductImages";
import Vue2Filters from 'vue2-filters'
import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import supplierMixins from "@/mixins/supplierMixins";
import addNewOrderMixins from "@/mixins/addNewOrderMixins";

export default {
  name: "OrderProductList",
  data: () => ({
    params: {
      sorting: -1,
      orderBy: "t_quantity"
    },
    showZeroStock: false,
  }),
  components: {ProductImages},
  mixins: [Vue2Filters.mixin, supplierMixins, addNewOrderMixins],
  computed: {
    ...mapGetters("order", ["_orderProducts"])
  },
  methods: {
    getOrderProductStatistics(event, productId) {
      this.$store.state.order.orderProductStatisticsProductId = productId;
      this.$root.$emit('bv::show::modal', 'order-product-statistics-modal', event.target)
    },
    productEdit(event, productId) {
      axios.post('/products/all', {id: productId})
          .then(res => {
            if (res.data.data[0]) {
              this.$store.commit('product/setEditProduct', res.data.data[0])
              this.$root.$emit('bv::show::modal', 'edit-product-modal', event.target)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ürün çekme hatası2',
                  variant: 'danger',
                  icon: 'XCircleIcon',
                }
              })
            }
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ürün çekme hatası',
                variant: 'danger',
                icon: 'XCircleIcon',
              }
            })
          })
    },
    showImages(event, product) {
      axios.post('/products/all', {id: product.product_id})
          .then(res => {
            if (res.data[0]) {
              this.$store.commit('product/setEditProduct', res.data[0]);
              this.$root.$emit('bv::show::modal', 'product-images-modal', event.target)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ürün çekme hatası2',
                  variant: 'danger',
                  icon: 'XCircleIcon',
                }
              })
            }
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ürün çekme hatası',
                variant: 'danger',
                icon: 'XCircleIcon',
              }
            })
          })
    },
  }
}
</script>

<style scoped>
.btnsize {
  margin-right: 3px;
  padding: 5px 3px;
  font-size: 12px
}
</style>
