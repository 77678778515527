<template>
  <b-col md="12" v-if="product.product" style="margin-top: 5px">
    <b-row>
      <b-col md="12">
        <b-dropdown variant="link" no-caret>
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
          </template>
          <b-dropdown-item @click="tyMatching(product.product_id)">
            <feather-icon icon="PlusCircleIcon"/>
            <span class="align-middle ml-50">TY SKU Ekle</span>
          </b-dropdown-item>
          <b-dropdown-item @click="hbMatching(product.product_id)">
            <feather-icon icon="PlusCircleIcon"/>
            <span class="align-middle ml-50">HB SKU Ekle</span>
          </b-dropdown-item>
          <b-dropdown-item @click="azMatching(product.product_id)">
            <feather-icon icon="PlusCircleIcon"/>
            <span class="align-middle ml-50">AZ SKU Ekle</span>
          </b-dropdown-item>
        </b-dropdown>
        <a v-if="product.product.hb_products.length>0"
           class="btn btnsize"
           v-for="mpProduct in product.product.hb_products"
           :key="mpProduct.id"
           target="mpproduct"
           :href="'https://www.hepsiburada.com/w-p-'+mpProduct.merchant_sku"
           :class="mpProduct.merchant_sku==product.store_product_sku?'btn-primary':'btn-outline-warning'"
        >HB</a>
        <a v-if="product.product.ty_products.length>0"
           class="btn btnsize"
           v-for="mpProduct in product.product.ty_products"
           :key="mpProduct.id"
           target="mpproduct"
           :href="'https://www.trendyol.com/w/w-w-p-'+mpProduct.productContentId"
           :class="mpProduct.merchant_sku==product.store_product_sku?'btn-primary':'btn-outline-info'"
        >TY</a>
        <a v-if="product.product.az_products.length>0"
           class="btn btnsize"
           v-for="mpProduct in product.product.az_products"
           :key="mpProduct.id"
           target="mpproduct"
           :href="'https://www.amazon.com.tr/gp/product/'+mpProduct.merchant_sku"
           :class="mpProduct.merchant_sku==product.store_product_sku?'btn-primary':'btn-outline-success'"
        >AMZ</a>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import addMpMatching from '@/mixins/addMpMatching'

export default {
  name: 'OrderProductStoreLink',
  mixins: [addMpMatching],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },

}
</script>

<style scoped>
.btnsize {
  margin-right: 3px;
  padding: 5px 3px;
  font-size: 12px
}
</style>
