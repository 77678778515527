import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {EventBus} from '@/event-bus'
import soundMixins from '@/mixins/soundMixins'

export default {
    mixins: [soundMixins],
    methods: {
        addToCard(type) {
            let basket
            if (this._selected == '') {
                basket = prompt('Lütfen sepet adı giriniz')
                basket = basket.trim()
                if (basket == '' || basket == null) {
                    alert('sepet adı geçersiz')
                    return
                }
                this.$store.commit('orderBasket/setSelected', basket)
            }
            basket = this._selected

            if (this.order.tracking.cargo_company_id == 1) {
                basket = basket + '-AK'
            } else if (this.order.tracking.cargo_company_id == 2) {
                basket = basket + '-SK'
            } else if (this.order.tracking.cargo_company_id == 3) {
                basket = basket + '-YK'
            } else if (this.order.tracking.cargo_company_id == 4) {
                basket = basket + '-HBJ'
            } else if (this.order.tracking.cargo_company_id == 5) {
                if (this.order.products.length > 1) {
                    basket = basket + '-TEX-C'
                } else {
                    basket = basket + '-TEX-T'
                }
            } else if (this.order.tracking.cargo_company_id == 7) {
                basket = basket + '-KG'
            } else if (this.order.tracking.cargo_company_id == 11) {
                basket = basket + '-MNG'
            }

            let data = {
                id: this.order.id,
                invoice_basket: {
                    order_id: this.order.id,
                    basket_name: basket,
                    type
                }
            }

            this.$store.dispatch('order/orderAddBasket', data)
                .then(res => {
                    EventBus.$emit('setBasketName', {
                        text: basket,
                        variant: res.variant
                    })
                    EventBus.$emit('setEventMessage', {
                        text: res.title,
                        variant: res.variant
                    })
                    this.addBasketBtnMesaj = {
                        title: res.title,
                        variant: res.variant,
                    }
                    this.autoPlay(res.variant)
                    this.$toast({
                        component: ToastificationContent,
                        props: res
                    })
                })
        },
        addToCardBack() {
            this.$store.dispatch('order/orderAddBasketBack', {
                id: this.order.id,
                invoice_basket: null
            })
                .then(res => {
                    this.addBasketBtnMesaj = {
                        title: res.title,
                        variant: res.variant,
                    }
                    EventBus.$emit('setBasketName', {
                        text: '',
                        variant: ''
                    })
                    EventBus.$emit('setEventMessage', {
                        text: res.title,
                        variant: res.variant
                    })
                    this.autoPlay(res.variant + '2')
                    this.$toast({
                        component: ToastificationContent,
                        props: res
                    })
                })
        },
        delFormCard() {
            this.$store.dispatch('order/orderDelBasket', {id: this.order.id})
                .then(res => {
                    this.addBasketBtnMesaj = {
                        title: res.title,
                        variant: res.variant,
                    }
                    this.autoPlay(res.variant + '2')
                    this.$toast({
                        component: ToastificationContent,
                        props: res
                    })
                })
        },
    }
}
